import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknClientsSoaConfigEditOverviewRoute extends Route {
  @service store;

  async model() {
    const id = this.modelFor('authenticated.crakn.clients.client.soa-config.edit').get('id');
    return await this.store.findRecord('v2/soaTemplate', id);
  }
}
