import { alias, match } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { routeMatchRegex } from 'crakn/utils/regex-helpers';

export default Controller.extend({
  application: controller(),
  currentRoute: alias('application.currentRouteName'),
  isFinancialRouteActive: match('currentRoute', routeMatchRegex('financials')),
  isPaymentRouteActive: match('currentRoute', routeMatchRegex('payments')),
  isInterestRouteActive: match('currentRoute', routeMatchRegex('interest')),
});
