import Controller from '@ember/controller';
import config from 'crakn/config/environment';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';

export default class CraknClientsEditReportsConfigController extends Controller {
  @controller('authenticated/crakn/clients/client/edit') edit;
  
  get isEnvironmentEnterpriseOrLocalhost() {
    return config.host.includes('enterprise') || config.host.includes('localhost');
  }

  @action
  save() {
    if (this.reportConfig.hasDirtyAttributes) {
      this.reportConfig
        .save()
        .then(() => this.edit.handleSaveSuccess())
        .catch(() => this.edit.handleSaveError());
    }
  }
}
