import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCraknClientsSoaConfigNewController extends Controller {
  @service router;
  @service flashes;

  @tracked showValidation = false;

  customFontSizes = [8, 9, 10, 11];

  @action
  setCustomFontSize(value) {
    this.model.customFontSize = Number(value);
  }

  @action
  cancelSoaTemplate(template) {
    template.rollbackAttributes();
    this.router.transitionTo('authenticated.crakn.clients.client.edit.soa-config', this.client.id);
  }

  @action
  saveSoaTemplate(template, event) {
    event.preventDefault();

    template.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidation = false;

        template
          .save()
          .then((data) => {
            this.flashes.addSuccess('Statement of Account saved!');
            this.send('refreshModel');
            this.router.transitionTo('authenticated.crakn.clients.client.soa-config.edit.overview', data.id);
          })
          .catch(() => {
            this.flashes.addError(
              'Oops! There was an error saving this Statement of Account.'
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }
}
