import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknClientsSoaConfigNewRoute extends Route {
  @service store;

  async model() {
    const client_id = this.modelFor('authenticated.crakn.clients.client').get('id');

    // Ensure the client is a valid v2/client model
    const client = await this.store.findRecord('v2/client', client_id);

    const newTemplate = this.store.createRecord('v2/soaTemplate', { client });

    return {
      client,
      newTemplate,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('client', model.client);
    controller.set('newTemplate', model.newTemplate);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('newTemplate', null);
    }
  }
}
