import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';

export default class CraknClientsEditOverviewController extends Controller {
  @controller('authenticated/crakn/clients/client/edit') edit;

  get clientNameMessage() {
    if (this.client && this.client.name) {
      return null;
    } else {
      return 'This field can\'t be blank';
    }
  }

  validate() {
    return !this.clientNameMessage;
  }

  @action
  save(event) {
    const isValid = this.validate();
    if (isValid) {
      if (this.clientNote.hasDirtyAttributes) {
        this.clientNote.save();
      }
      this.edit.save(event);
    }
  }

  @action
  cancelEditClient() {
    this.edit.cancelEditClient();
  }
}
