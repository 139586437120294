import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SelectionProductSummaryComponent extends Component {
  @service store;

  @tracked isEditingProd = false;
  @tracked required = null;
  @tracked showDialog = false;
  @tracked isInPackage = false;
  @tracked product;
  @tracked required = false;
  @tracked selectionPackageSlotProduct = null;

  constructor() {
    super(...arguments);
    const selectionPackageSlotProduct = this.args.model.selectionPackageSlotProducts.firstObject;

    set(this, 'selectionPackageSlotProduct', selectionPackageSlotProduct);
    set(this, 'product', selectionPackageSlotProduct?.get('product'));
    set(this, 'required', selectionPackageSlotProduct?.get('packageSlotRequired'));
    this.setIsInPackage();
  }

  get idKey() {
    return this.args.model.get('id');
  }

  async setIsInPackage() {
    const packageId = this.args.package.get('id'); // Assuming the package is passed in and has an id property

    const results = await this.store.query('v2/package_slot_product', {
      package_id: packageId,
      product_id: this.product.get('id'),
    });

    set(this, 'isInPackage', results.length > 0);
  }

  @action
  removeProduct() {
    this.args.onRemoveProduct(this.args.model);
    set(this, 'showDialog', false);
  }

  @action
  async saveProduct() {
    set(this, 'isEditingProd', false);
    await this.selectionPackageSlotProduct.save();
  }

  @action
  revertProduct() {
    set(this, 'isEditingProd', false);
    this.selectionPackageSlotProduct.rollbackAttributes();
  }
}
