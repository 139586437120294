import Route from '@ember/routing/route';

export default class CraknClientsEditReceiptConfigRoute extends Route {
  model() {
    return this.modelFor('authenticated.crakn.clients.client');
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('client', model);
  }
}
