import Route from '@ember/routing/route';

export default class CraknClientsEditCaseSectionConfigRoute extends Route {
  model() {
    const clientId = this.modelFor('authenticated.crakn.clients.client').get('id');
    return this.store.findRecord('v2/client', clientId, {
      include: 'selection-validations-config'
   });
  }

  createSelectionValidationConfig() {
    return this.store.createRecord('v2/selectionValidationsConfig', {
      client: this.currentModel
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const selectionValidationsConfigId = model.get(
      'selectionValidationsConfig.id'
    );

    if (model.get('selectionValidationsConfig.id')) {
      this.store
        .findRecord(
          'v2/selectionValidationsConfig',
          selectionValidationsConfigId
        )
        .then((result) => controller.set('selectionValidationsConfig', result));
      // controller.set('selectionValidationsConfig', model.get('selectionValidationsConfig'));
    } else {
      controller.set(
        'selectionValidationsConfig',
        this.createSelectionValidationConfig()
      );
    }

    controller.set('client', model);
  }
}
