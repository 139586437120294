import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CraknClientsFeatureConfigRoute extends Route {
  @service clientConfig;

  model() {
    return this.modelFor('authenticated.crakn.clients.client');
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const selectionValidationConfigResponse = await this.store.query(
      'v2/selectionValidationsConfig',
      { client_id: model.id }
    )

    let selectionValidationConfig;

    if (selectionValidationConfigResponse.length > 0) {
      selectionValidationConfig = selectionValidationConfigResponse.firstObject;
    } else {
      const v2Client = await this.store.findRecord('v2/client', model.id);

      selectionValidationConfig = this.store.createRecord(
        'v2/selectionValidationsConfig',
        { client: v2Client }
      );
    }

    controller.set('selectionValidationConfig', selectionValidationConfig)
    controller.set('client', model);
  }

  @action
  resetController(controller) {
    controller.set('searchValue', null);
    controller.set('client', null);
    controller.set('selectionValidationConfig', null)
    // Reset clientConfig filter results when transitioning from page
    this.clientConfig.filterFeatures('All');
  }

  @action
  willTransition(transition) {
    if (this.currentModel.get('hasDirtyAttributes')) {
      if (confirm('You have unsaved changes. Are you sure?')) {
        this.currentModel.rollbackAttributes();
        return true;
      } else {
        transition.abort();
      }
    }
  }
}
