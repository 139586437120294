import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CraknClientsEditFormsConfigController extends Controller {
  @controller('authenticated/crakn/clients/client/edit') edit;

  @service api;
  @service flashes;
  @service intl;
  @service session;
  @service store;

  @tracked canMigrate;
  @tracked isMigrating = false;
  @tracked migrationPending = false;
  @tracked showTfeMigrationConfirmDialog = false;
  @tracked tfeMigrationComplete = false;

  constructor() {
    super(...arguments);
  }

  async handleSaveSuccess() {
    const updatedSession = await this.api.json.get('session');
    const session = updatedSession.parsedJson.session;
    this.session.currentClient = session.client;
  }

  async fetchIntegrationService() {
    const clientId = this.model.id;

    const response = await this.api.json.post('integration_services/tfe', {
      body: {
        integration_service: {
          client_id: clientId
        }
      }
    });

    this.store.pushPayload('integrationService', response.parsedJson);

    return this.store.peekRecord('integrationService', response.parsedJson.integration_service.id);
  }

  async pollMigrationStatus(timeout) {
    let elapsedTime = 0;

    const interval = setInterval(async () => {
      if (elapsedTime > timeout) {
        clearInterval(interval);
        this.isMigrating = false;
        return;
      }

      const integrationService = await this.fetchIntegrationService();
      const migrationStatus = integrationService?.tfe_kase_forms_migration_status;

      this.isMigrating = migrationStatus === 'processing';
      this.tfeMigrationComplete = migrationStatus === 'completed';
      this.isfailed = migrationStatus === 'failed';

      if (!this.isMigrating) {
        clearInterval(interval);

        if (this.tfeMigrationComplete) {
          this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.forms-config.banners.success'));
        }

        if (this.isFailed) {
          this.flashes.addError(this.intl.t('authenticated.crakn.clients.edit.forms-config.banners.error'));
        }
      }

      elapsedTime += 10;
    }, 10000);
  }

  @action
  async migrateFormsToTfe() {
    this.isMigrating = true;
    this.showTfeMigrationConfirmDialog = false;

    this.migrationPending = true;
    this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.forms-config.banners.migrationStarted'));
    try {
      await this.api.json.post('tfe/generated_documents/migrate');
    } finally {
      this.migrationPending = false;
      this.pollMigrationStatus(600);
    }
  }

  @action
  async save(event) {
    await this.edit.save(event);
    this.handleSaveSuccess();
  }

  @action
  async checkMigrationStatus() {
    try {
      const integrationService = await this.fetchIntegrationService();
      const migrationStatus = integrationService?.tfe_kase_forms_migration_status;
			const clientId = this.model.id;

      if (!migrationStatus || migrationStatus === 'failed') {
        const kaseForms = await this.store.query('kaseForm', {
          client_id: clientId,
          status: 'completed',
          tfe_document_id: null,
          tfe_migration_at: null
        });

        this.canMigrate = kaseForms.length > 0;
      } else {
        this.canMigrate = false;
      }

      this.tfeMigrationComplete = migrationStatus === 'completed';

      if (this.migrationPending || migrationStatus === 'processing') {
        this.isMigrating = true;
        this.pollMigrationStatus(600);
      }
    } catch (error) {
      console.error('Error checking migration status:', error);
    }
  }
}
