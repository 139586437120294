import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CraknClientsEditFormsConfigRoute extends Route {
  @service api;

  model() {
    return this.modelFor('authenticated.crakn.clients.client');
  }

  async setupController(controller, model) {
    super.setupController(controller, model);

    controller.set('client', model);
  }
}
