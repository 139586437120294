import Controller from '@ember/controller';
import Search from 'crakn/mixins/search';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTfeFormsEditLocationAccessController extends Controller.extend(Search) {
  @service session;
  @service flashes;
  @service api;
  @service store;
  @service intl;

  @tracked canEnableLocations = false;
  @tracked checkedLocations = [];
  @tracked enabledDocuments;
  @tracked enabledLocations = [];
  @tracked filteredLocations = [];
  @tracked isLoading = true;
  @tracked isPopoutVisible = false;
  @tracked location_name = '';
  @tracked organization_id = null;
  @tracked page = 1;
  @tracked popout_location_name = '';
  @tracked popout_organization_id = null;
  @tracked temp_location_name = '';
  @tracked temp_popout_location_name = '';
  @tracked totalUnassignedLocationsCount = 0;
  @tracked unassignedLocations = [];

  queryParams = ['page', 'location_name', 'organization_id'];
  defaultPage = 1;

  @action
  async openPopout() {
    const locations = await this.getUnassignedLocations();

    set(this, 'unassignedLocations', locations);
    set(this, 'isPopoutVisible', true);
  }

  @action
  async closePopout() {
    set(this, 'isPopoutVisible', false);
    set(this, 'popout_organization_id', '');
    set(this, 'popout_location_name', '');
    set(this, 'temp_popout_location_name', '');

    if (this.checkedLocations.length > 0) {
      const checkedLocations = [...this.checkedLocations];

      checkedLocations.forEach((location) => {
        set(location, 'checked', false);
        this.checkedLocations.removeObject(location);
      });
    }

    this.getUnassignedLocations();
  }

  @action
  setPopoutLocationName(value) {
    set(this, 'temp_popout_location_name', value)
    debounce(this, this._setPopoutLocationName, this.temp_popout_location_name, 150);
  }

  _setPopoutLocationName(value) {
    set(this, 'popout_location_name', value);
    debounce(this, this.updateFilteredLocations, 150);
  }

  @action
  setLocationName(value) {
    set(this, 'temp_location_name', value)
    debounce(this, this._setLocationName, this.temp_location_name, 400);
  }

  _setLocationName(value) {
    set(this, 'location_name', value)
    debounce(this, this.updateAssignedFilteredLocations, 150);
  }

  @action
  organizationDidChange(event) {
    set(this, 'popout_organization_id', event)
    debounce(this, this.updateOrganizationLocations, 150);
  }

  async updateOrganizationLocations() {
    const locations = await this.getUnassignedLocations();
    set(this, 'unassignedLocations', locations);
    this.updateFilteredLocations();
  }

  @action
  removeEnabledDocument(enabledDocument) {
    enabledDocument
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.admin.tfe.forms.edit.location-access.buttons.messages.removeSuccess'));
        this.refreshModel();
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.tfe.forms.edit.location-access.buttons.messages.removeError'));
      });
  }

  @action
  refreshModel() {
    this.send('refreshRoute');
  }

  async getUnassignedLocations() {
    const tfeEnabledDocuments = await this.tfeEnabledDocuments();
    const locations = await this.getLocations();
    const assignedIdentifiers = new Set(tfeEnabledDocuments.map(doc => doc.secondary_remote_identifier));
    const unassignedLocations = locations.filter(location => !assignedIdentifiers.has(location.uuid));

    unassignedLocations.forEach(location => {
        location.checked = false;
    });

    return unassignedLocations;
  }

  async tfeEnabledDocuments() {
    const response = await this.api.json.get('tfe/enabled_documents', {
        params: { document_id: this.model.document.id, per_page: 1000, current_client: true }
    });

    return response.parsedJson.enabled_documents;
  }

  async getLocations() {
    return this.store.query('v2/location', {
      per_page: 1000,
      archived: false,
      organization_id: this.popout_organization_id,
      client_id: this.session.currentClient.id
    });
  }

  updateFilteredLocations() {
    const query = this.temp_popout_location_name.toLowerCase();
    const locationFilter = this.unassignedLocations.filter(location =>
      location.name.toLowerCase().includes(query)
    );

    set(this, 'filteredLocations', locationFilter);
  }

  updateAssignedFilteredLocations() {
    const query = this.location_name.toLowerCase();
    this.enabledLocations = this.enabledLocations.filter(location =>
      location.name.toLowerCase().includes(query)
    );
  }
}
