import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknClientsEditSfgsConfigEditOverviewRoute extends Route {
  @service store;

  async model() {
    const client = this.modelFor('authenticated.crakn.clients.client');
    const template = this.modelFor('authenticated.crakn.clients.client.sfgs-config.edit');
    const systemSfgsTemplates = await this.store.query('v2/systemSfgsTemplate', {});
    const systemSfgsTemplateNames = systemSfgsTemplates.mapBy('name');

    return {
      client,
      template,
      systemSfgsTemplateNames
    };  
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      systemSfgsTemplateNames: model.systemSfgsTemplateNames,
      template: model.template,
      client: model.client
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('systemSfgsTemplateNames', []);
    }
  }
}
