import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2InterestCalculationModel extends Model {
  @attr('number') appliedById;
  @attr('moment-date') dateApplied;
  @attr('number') gracePeriod;
  @attr('price') interestAmount;
  @attr('moment-date') interestStartDate;
  @attr('moment-date') invoiceAddOnDate;
  @attr('boolean') isManualCalculation;
  @attr('string') note;

  @belongsTo('v2/user') appliedBy;
  @belongsTo('v2/selection') selection;
}
