import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class CraknClientRoute extends Route {
  model(params) {
    return this.store.findRecord('client', params.client_id);
  }
  
  setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('client', model);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
