import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedAdminTfeFormsEditLocationAccessRoute extends SearchRoute {
  @service api;
  @service store;
  @service session;

  queryParams = {
    page: {
      replace: true,
      refreshModel: true
    },
    location_name: {
      refreshModel: true
    },
    popout_location_name: {
      refreshModel: true
    },
    popout_organization_id: {
      refreshModel: true
    },
    organization_id: {
      refreshModel: true
    }
  }

  defaultPage = 1;

  async model(params) {
    const document = await this.modelFor('authenticated.admin.tfe.forms.edit');
    const enabledDocuments = await this.store.query('tfe/enabled-document', {
      params,
      current_client: true,
      document_id: document.get('id'),
      archived: false,
      secondary_remote_identifier_type: 'location',
      page: params.page || this.defaultPage
    });
    const enabledLocations = await this.store.query('v2/location', {
      organization_id: params.organization_id,
      uuid: enabledDocuments.mapBy('secondaryRemoteIdentifier')
    })

    return {
      document,
      enabledDocuments,
      enabledLocations
    };
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.setProperties({
      temp_location_name: controller.location_name  || '',
      enabledDocuments: model.enabledDocuments,
      enabledLocations: model.enabledLocations,
      meta: model.enabledDocuments.get('meta'),
      isLoading: false,
      canEnableLocations: model.enabledDocuments.length > 0 || controller.isPopoutVisible
    });
    
    controller.updateAssignedFilteredLocations();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.store.unloadAll('tfe/enabled_document');

      controller.setProperties({
        location_name: '',
        organization_id: null,
        enabledDocuments: null,
        enabledLocations: null,
        page: 1,
        meta: null
      });
    }
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
