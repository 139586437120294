import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class CraknClientsEditSfgsConfigRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    template_query: {
      refreshModel: true
    }
  }

  async model(params) {
    const clientId = this.modelFor('authenticated.crakn.clients.client').get('id');

    const client = this.store.findRecord(
      'v2/client', clientId, {
        include: 'contract-templates'
      });

    const contractTemplates = await this.store.query('v2/contractTemplate', {
      client_id: clientId,
      page: params.page,
      per_page: 10,
      template_query: params.template_query
    });

    return {
      client,
      contractTemplates,
      isSearchActive: !!params.template_query
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const systemSfgsTemplates = this.store.query('v2/systemSfgsTemplate', {});

    if (!model.isSearchActive && controller.originalContractTemplatesCount === null) {
      controller.set('originalContractTemplatesCount', model.contractTemplates.meta.total);
    }
    controller.set('systemSfgsTemplates', systemSfgsTemplates);
    controller.set('client', model.client);

    if (!model.isSearchActive && controller.originalContractTemplatesCount === null) {
      controller.set('originalContractTemplatesCount', model.contractTemplates.meta.total);
    }
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.crakn.manage.clients'
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          page: 1,
          template_query: ''
        });
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
