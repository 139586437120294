import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ClientConfigService extends Service {
  @service intl;

  categories = [
    'All',
    'Admin Navigation',
    'Case',
    'Case Financial',
    'Event',
    'Global Navigation',
    'Integrations',
    'Posting Validations',
    'Product and Inventory'
  ];

  adminNavigationFeatures = [
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewUsers'),
      value: 'canViewUsers'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewGroups'),
      value: 'canViewGroups'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewOnlinePlanner'),
      value: 'canViewOnlinePlanner'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewOrganizations'),
      value: 'canViewOrganizations'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewLocations'),
      value: 'canViewLocations'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewFinancialConfigurations'),
      value: 'canViewFinancialConfigurations',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.adjustments'),
          value: 'canViewFinancialConfigAdjustments'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.bankAccounts'),
          value: 'canViewFinancialConfigBankAccounts'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.discounts'),
          value: 'canViewFinancialConfigDiscounts'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.gLAccounts'),
          value: 'canViewFinancialConfigAccounts'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.interests'),
          value: 'canViewFinancialConfigInterests'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.payments'),
          value: 'canViewFinancialConfigPayments'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.postingPeriods'),
          value: 'canViewFinancialConfigPostingPeriods'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.specialTaxes'),
          value: 'canViewFinancialConfigSpecialTaxes'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.sync'),
          value: 'canViewFinancialConfigSync'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.financialConfiguration.taxItems'),
          value: 'canViewFinancialConfigTaxItems'
        }
      ]
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View General Price Lists?',
      value: 'canViewGeneralPriceLists'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Admin Forms?',
      value: 'canViewFormsAdmin'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Data?',
      value: 'canViewData'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Info?',
      value: 'canViewInfo'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Audit Logs?',
      value: 'canViewAuditLogs'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Tribute Video Admin?',
      value: 'canViewTributeVideoAdmin'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Custom Field Configurations?',
      value: 'canViewCustomFieldsConfig'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: 'Can View Poems & Prayers?',
      value: 'canViewPoems'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewTributePay'),
      value: 'canViewTributePay',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.admin.canViewSmsPaymentLink'),
          value: 'canViewSmsPaymentLink'
        }
      ]
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewLocationSpecificUsers'),
      value: 'canViewLocationSpecificUsers',
      conditionalFeatures: [
        {
          model: 'client',
          placeholder: this.intl.t('services.client-config.checkboxes.admin.newUserOrLocationDefaultBehavior'),
          value: 'newUserOrLocationDefaultBehavior',
          inputType: 'select',
          options: [
            { label: this.intl.t('services.client-config.checkboxes.admin.defaultBehaviorAutomatic'), value: 'automatic' },
            { label: this.intl.t('services.client-config.checkboxes.admin.defaultBehaviorManual'), value: 'manual' }
          ]
        }
      ]
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canViewLocationSpecificForms'),
      value: 'canViewLocationSpecificForms'
    },
    {
      category: 'Admin Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.admin.canUseEnhancedUserSearch'),
      value: 'canUseEnhancedUserSearch'
    },
  ]

  caseFeatures = [
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewTasks'),
      value: 'canViewTasks'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewTasksAdmin'),
      value: 'canViewTasksAdmin'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewObituaryGenerator'),
      value: 'canViewObitGenerator'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewContactManager'),
      value: 'canViewContactManager'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewEsignature'),
      value: 'canViewEsignature'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.blockEsignForUnpostedContracts'),
      value: 'blockEsignForUnpostedContracts'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.isEsignFieldTest'),
      value: 'isEsignFieldTest'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.autoKaseNumbering'),
      value: 'autoKaseNumbering',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.case.forceAutoKaseNumbering'),
          value: 'forceAutoKaseNumbering'
        }
      ]
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewCaseTags'),
      value: 'canViewKaseTags',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.case.canManageCaseTags'),
          value: 'canViewKaseTagsAdmin'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.case.canUseSingleKaseTag'),
          value: 'canUseSingleKaseTag'
        }
      ]
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canSetKaseStatusFilter'),
      value: 'canSetKaseStatusFilter'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewDigitalSignatures'),
      value: 'canViewDigitalSignatures'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewCaseDispositionIcons'),
      value: 'canViewCaseDispositionIcon'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewPreneedCategories'),
      value: 'canViewPreneedCategories'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewForms'),
      value: 'canViewForms'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewCaseFinancial'),
      value: 'canViewCaseFinancial'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewPreneed'),
      value: 'canViewPreneedSection',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.case.canViewPreneedInfoMessage'),
          value: 'canViewPreneedInfoMessage'
        }
      ]
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewTransportation'),
      value: 'canViewTransportationSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewPreparations'),
      value: 'canViewPreparationsSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewReferralInfo'),
      value: 'canViewReferralInfoSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewServiceInfo'),
      value: 'canViewServiceInfoSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewCaseInquiries'),
      value: 'canViewInquiriesSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewDocuments'),
      value: 'canViewDocumentsSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewPrintables'),
      value: 'canViewPrintablesSection',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.case.canViewMimsStationary'),
          value: 'canViewMimsStationary'
        }
      ]
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewObituary'),
      value: 'canViewObituarySection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewFamilyPortal'),
      value: 'canViewFamilyPortal',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.case.sendFamilyPortalEmailInvite'),
          value: 'sendFamilyPortalEmailInvite'
        }
      ]
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewChainOfCustody'),
      value: 'canViewChainOfCustody'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewCremationAuthorization'),
      value: 'canViewCremationAuthorizationSection',
      conditionalElements: [
        {
          component: 'g/tms/cremation-header-select'
        }
      ]
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewLinks'),
      value: 'canViewKaseLinks'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewDisposition'),
      value: 'canViewDisposition'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewFamilyFriends'),
      value: 'canViewFamilyFriends'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewVeteranInfo'),
      value: 'canViewVeteranInfoSection'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewEvents'),
      value: 'canViewEvents'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewCustomFields'),
      value: 'canViewCustomCaseFields'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.canViewIsCovid19Case'),
      value: 'canViewIsCovid19Case'
    },
    {
      category: 'Case',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.case.onlyFuneralDirectorsCanBeLeadStaffMembers'),
      value: 'onlyFuneralDirectorsCanBeLeadStaffMembers'
    }
  ];

  caseFinancialFeatures = [
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canLockPostingPeriods'),
      value: 'canLockPostingPeriods',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.enforcePostingPeriodLocks'),
          value: 'enforcePostingPeriodLocks'
        }
      ]
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canMarkAsBadDebt'),
      value: 'canMarkBadDebt'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewSpecialTax'),
      value: 'canViewSpecialTaxes'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewDiscounts'),
      value: 'canViewDiscounts'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewInterests'),
      value: 'canViewInterests'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canAssignInsuranceToPayments'),
      value: 'canAssignInsuranceToPayments'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewLineItemAdjustments'),
      value: 'canViewLineItemAdjustments'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewMiscSales'),
      value: 'canViewMiscSales'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewDoNotExport'),
      value: 'canViewDoNotExport'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.defaultDueDate'),
      value: 'defaults_due_date'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.defaultContractToDeathDate'),
      value: 'defaultContractToDeathDate'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.setContractDateToPostedDateIfNoEvents'),
      value: 'setContractDateToPostedDateIfNoEvents'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewGenerateSfgsButton'),
      value: 'canViewGenerateSfgs'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.relabelContractToInvoice'),
      value: 'relabelContractToInvoice'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewBatchPayments'),
      value: 'canViewBatchPayments',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewGeneralPayments'),
          value: 'canViewGeneralPayments'
        }
      ]
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewAdjustmentGains'),
      value: 'canViewAdjustmentGains'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.includePostingDateInFinancialDetailsExport.label'),
      value: 'includePostingDateInFinancialDetailsExport'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.requireReasonForUnposting.label'),
      value: 'requireReasonForUnposting'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canViewCustomSelectionSorting.label'),
      value: 'canViewCustomSelectionSorting'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canViewStatementCategories.label'),
      value: 'canViewStatementCategories'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canSeparateTaxCalculations.label'),
      value: 'canSeparateTaxCalculations'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('authenticated.crakn.clients.edit.feature-config.checkboxes.canViewEnhancedPackages.label'),
      value: 'canViewEnhancedPackages'
    },
    {
      category: 'Case Financial',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.caseFinancial.canViewInterestSection'),
      value: 'canViewInterestSection',
      disclaimer:this.intl.t('services.client-config.disclaimers.interestSection')
    }
  ];

  eventFeatures = [
    {
      category: 'Event',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.events.canViewFamilyArrivalTime'),
      value: 'canViewFamilyArrivalTime'
    },
    {
      category: 'Event',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.events.canViewEmployeeArrivalTime'),
      value: 'canViewEmployeeArrivalTime'
    },
    {
      category: 'Event',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.events.canViewVehicles'),
      value: 'canViewVehicles'
    },
    {
      category: 'Event',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.events.eventStaffMemberInvites'),
      value: 'eventStaffMemberInvites',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.events.sendResponsesToCaseLead'),
          value: 'eventStaffMemberLeadResponse'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.events.sendResponsesToOriginalAssigner'),
          value: 'eventStaffMemberAssigneeResponse'
        }
      ]
    }
  ];

  globalNavigationFeatures = [
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewCases'),
      value: 'canViewKases'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewContacts'),
      value: 'canViewContacts'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewAccounting'),
      value: 'canViewAccounting',
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewWhiteboard'),
      value: 'canViewWhiteboard'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewCalendar'),
      value: 'canViewCalendar'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewPlaces'),
      value: 'canViewPlaces'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewReports'),
      value: 'canViewReports'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewInquiries'),
      value: 'canViewInquiriesGlobal'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewFinancialDetail'),
      value: 'canViewFinancialDetail'
    },
    {
      category: 'Global Navigation',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.globalNavigation.canViewChecks'),
      value: 'canViewCheckWriting',
      disclaimer:this.intl.t('services.client-config.disclaimers.checkWriting')
    },
  ]

  integrationFeatures = [
    {
      category: 'Integrations',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.integrations.canViewCemsites'),
      value: 'canViewCemsites'
    },
    {
      category: 'Integrations',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.integrations.canViewOhioEdrs'),
      value: 'canViewOhioEdrs'
    },
    {
      category: 'Integrations',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.integrations.canViewGrowthEngine'),
      value: 'canViewGrowthEngine'
    },
    {
      category: 'Integrations',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.integrations.canHideDeathNotice'),
      value: 'canHideDeathNotice',
      conditionalFeatures: [
        {
          model: 'client',
          placeholder: this.intl.t('services.client-config.checkboxes.integrations.defaultStateOfDeathNotice.label'),
          value: 'defaultStateOfDeathNotice',
          inputType: 'select',
          options: [
            {
              label: this.intl.t('services.client-config.checkboxes.integrations.defaultStateOfDeathNotice.options.hide'),
              value: 'hide'
            },
            {
              label: this.intl.t('services.client-config.checkboxes.integrations.defaultStateOfDeathNotice.options.show'),
              value: 'show'
            }
          ]
        }
      ]
    }
  ];

  postingValidations = [
    {
      category: 'Posting Validations',
      model: 'selectionValidationConfig',
      labelText: this.intl.t('services.client-config.checkboxes.postingValidations.caseTag'),
      value: 'canUseKaseTagValidation'
    },
    {
      category: 'Posting Validations',
      model: 'selectionValidationConfig',
      labelText: this.intl.t('services.client-config.checkboxes.postingValidations.staffMember'),
      value: 'canUseStaffMemberValidation'
    },
    {
      category: 'Posting Validations',
      model: 'selectionValidationConfig',
      labelText: this.intl.t('services.client-config.checkboxes.postingValidations.disposition'),
      value: 'canUseDispositionValidation'
    },
    {
      category: 'Posting Validations',
      model: 'selectionValidationConfig',
      labelText: this.intl.t('services.client-config.checkboxes.postingValidations.purchaser'),
      value: 'canUsePurchaserValidation',
      conditionalFeatures: [
        {
          model: 'selectionValidationConfig',
          labelText: this.intl.t('services.client-config.checkboxes.postingValidations.purchaserRelationship'),
          value: 'canUsePurchaserRelationshipValidation'
        }
      ]
    },
    {
      category: 'Posting Validations',
      model: 'selectionValidationConfig',
      labelText: this.intl.t('services.client-config.checkboxes.postingValidations.postingPeriod'),
      value: 'canUsePostingPeriodValidation'
    }
  ];

  productInventoryFeatures = [
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewAdditionalProductInfo'),
      value: 'canViewAdditionalProductInfo'
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewInventory'),
      value: 'canViewInventory',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewPullFromInventory'),
          value: 'canViewPullFromInventory'
        },
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewPullFromBeaconfpInventory'),
          value: 'canViewPullFromBeaconfpInventory'
        }
      ]
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewVendors'),
      value: 'canViewVendors'
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewPriceListDates'),
      value: 'canViewPriceListDates'
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewCogsCustomProducts'),
      value: 'canViewCogsCustomProducts'
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.useCustomProducts'),
      value: 'hasCustomProducts'
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.hasDiscretionaryItems'),
      value: 'hasDiscretionaryItems'
    },
    {
      category: 'Product and Inventory',
      model: 'client',
      labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewPackageSlotFlexibleItems'),
      value: 'canViewPackageSlotFlexibleItems',
      conditionalFeatures: [
        {
          model: 'client',
          labelText: this.intl.t('services.client-config.checkboxes.productAndInventory.canViewPackageSlotAllowances'),
          value: 'canViewPackageSlotAllowances',
          disclaimer: this.intl.t('services.client-config.disclaimers.packageSlotAllowances')
        },
      ]
    }
  ];

  allFeatures = [
    ...this.adminNavigationFeatures,
    ...this.caseFeatures,
    ...this.caseFinancialFeatures,
    ...this.eventFeatures,
    ...this.globalNavigationFeatures,
    ...this.integrationFeatures,
    ...this.postingValidations,
    ...this.productInventoryFeatures
  ];

  @tracked features = this.allFeatures;
  @tracked currentFilter = 'All';

  filterFeatures(categoryType) {
    this.currentFilter = categoryType;

    if (categoryType == 'All') {
      this.features = this.allFeatures;
    } else {
      this.features = this.allFeatures.filter(
        (feature) => feature.category === categoryType
      );
    }
  }

  searchFeatures(value) {
    if (this.currentFilter !== 'All') {
      this.currentFilter = 'All';
    }

    this.features = this.allFeatures.filter((feature) =>
      feature.labelText.toLowerCase().includes(value.toLowerCase())
    );
  }
}
