import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CraknClientsEditCoaConfigController extends Controller {
  @service router;
  @service flashes;
  @service session;

  @tracked showValidation = false;
  @tracked isEditing = false;
  @tracked showDialog = false;
  @tracked templateEditId;
  @tracked templateDeleteId;
  @tracked newOrLastSoa;

  customFontSizes = [8, 9, 10, 11];
  queryParams = ['page', 'per_page', 'archived'];

  @action
  showDeleteDialog(templateId) {
    set(this, 'showDialog', true);
    set(this, 'templateDeleteId', templateId);
  }

  @action
  editTemplate(templateId) {
    set(this, 'isEditing', true);
    set(this, 'templateEditId', templateId);
  }

  @action
  cancelSoaTemplate(template) {
    set(this, 'isEditing', false);
    set(this, 'templateEditId', null);
    template.rollbackAttributes();
  }

  @action
  saveSoaTemplate(template, event) {
    event.preventDefault();

    template.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidation = false;

        template
          .save()
          .then(() => {
            this.flashes.addSuccess('Statement of Account saved!');
            this.send('refreshModel');
            set(this, 'isEditing', false);
            set(this, 'templateEditId', null);
          })
          .catch(() => {
            this.flashes.addError(
              'Oops! There was an error saving this Statement of Account.'
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  deleteSoaTemplate(template) {
    template
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess('Statement of Account deleted!');
        set(this, 'templateDeleteId', null);
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError(
          'Oops! There was an error deleting this Statement of Account.'
        );
      });
  }
}
