import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknClientsEditSfgsConfigEditOverviewController extends Controller {
  @service flashes;
  @service router;

  @tracked systemSfgsTemplateNames = [];
  @tracked template;

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    'Invoice' : 'Contract';
  }

  get backToRoute() {
    return `/crakn/clients/${this.client.get('id')}/edit/sfgs-config`;
  }

  @action
  saveContractTemplate() {
    const template = this.template;
    template.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidation = false;

        template
          .save()
          .then(() => {
            this.flashes.addSuccess(`${this.contractLabel} Template saved!`);
            this.router.transitionTo(this.backToRoute);
          })
          .catch(() => {
            this.flashes.addError(
              `Oops! There was an error saving this ${this.contractLabel} Template.`
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  cancelContractTemplate() {
    this.template.rollbackAttributes();
    this.router.transitionTo(this.backToRoute);
  }
}
