import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class CraknClientsController extends SearchController {
  @service api;
  @service flashes;
  @service router;
  @service store;

  @tracked newClient = null;
  @tracked addNew = false;

  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  get clientNameMessage() {
    if (this.addNew || (this.newClient && this.newClient.name)) {
      return null;
    } else {
      return 'This field can\'t be blank';
    }
  }

  validate() {
    return !this.clientNameMessage;
  }

  @action
  switchClient(client, evt) {
    evt.preventDefault();

    this.api.json
      .post('clients/switch', { body: { id: client.id } })
      .then((response) => {
        if (response.ok) {
          this.router.transitionTo('/logout');
        } else {
          this.flashes.addError('Oh No! There was an error.');
        }
      });
  }

  @action
  toggleAdding() {
    if (this.newClient) {
      this.newClient.rollbackAttributes();
      this.newClient = null;
    } else {
      this.newClient = this.store.createRecord('client');
      this.addNew = true;
    }
  }

  @action
  createClient() {
    this.addNew = false;
    const isValid = this.validate();
    if (isValid) {
      this.showValidation = false;
      this.newClient
        .save()
        .then((client) => {
          this.flashes.addSuccess('Client created successfully!');
          this.newClient = null;
          this.router.transitionTo(
            'authenticated.crakn.clients.client.edit.overview',
            client.id
          );
        })
        .catch(() => {
          this.flashes.addError('An error has occurred');
        });
    } else {
      this.showValidation = true;
    }
  }
}
